

.cursive {
  font-family: "Amatic SC", cursive;
  font-weight: 700;
}

.prose img {
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
}

/*body {
  background-color: #e24141;
}*/

.card-title {
  color: rgba(255,245,245);
  border-radius: 6px;
  font-size: 1.125rem;
  font-weight: 700;
  padding: 24px 16px;
  background-color: rgba(197, 48, 48, 0.75);
  display: inline-block;
}

.card-title .published {
  font-size: 11px;
  display: block;
  margin-bottom: 5px;
}

.card-img-overlay {
  left: inherit;
  top: inherit;
}

.card-img {
  object-fit: cover;
  object-position: top;
  height: 100%;
  width: 100%;
}

.card > a {
  display: block;
  border: none !important;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15) !important;
  width: 100%;
  height: 100%;
}

.card > a::before {
  content: "";
  height: 100%;
  width: 8px;
  background-color: rgb(155, 44, 44);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 12;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

figure {
  display: inline-block;
  margin: 10px;
}

.contentItem {
  width: 900px;
  margin: 0 auto;
  text-align: left;

}

.contentItem img {
  width: 100%;
}

.dropdown-menu li {
  position: relative;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu .dropdown-menu {
  top: 10%;
  left: 100%;
  margin-top: -1px;
}

nav li:hover > ul.dropdown-menu {
    display: block;
}